/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react/no-unstable-nested-components */
import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import type { ReactourStep, ReactourStepContentArgs, ReactourStepPosition } from 'reactour'
import Tour from 'reactour'

import TourStep from 'src/components/pages/Tour/TourComponents/TourStep'
import { useAuth } from 'src/components/providers/AuthProvider'
import type { OnboardingItems } from 'src/models/Organisation'

type Props = {
  readonly onboardingKey: keyof OnboardingItems
  readonly selector: string
  readonly mobileDirection: ReactourStepPosition
  readonly desktopDirection: ReactourStepPosition
  readonly title: string
  readonly description: string
}

const SimpleTour = (props: Props) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const { currentOrganisation, updateCurrentOrganisation } = useAuth()
  const [open, setOpen] = useState(false)

  const closeTour = () => {
    setOpen(false)
    if (currentOrganisation) {
      void updateCurrentOrganisation({
        ...currentOrganisation,
        onboarding: {
          ...currentOrganisation.onboarding,
          [props.onboardingKey]: false,
        },
      })
    }
  }

  useEffect(() => {
    if (currentOrganisation?.onboarding[props.onboardingKey]) {
      setOpen(currentOrganisation.onboarding[props.onboardingKey])
    }
  }, [currentOrganisation?.onboarding[props.onboardingKey]])

  const myRef = useRef(document.getElementById(props.selector))

  useEffect(() => {
    const element = document.getElementById(props.selector)
    if (element) myRef.current = element

    if (myRef.current) {
      myRef.current.addEventListener('click', closeTour)
    }
    return () => {
      if (myRef.current) {
        myRef.current.removeEventListener('click', closeTour)
      }
    }
  }, [myRef.current])

  const steps: ReactourStep[] = [
    {
      content: ({ close, goTo, step }: ReactourStepContentArgs) =>
        <TourStep
          close={close}
          description={props.description}
          final
          finalAction={() => closeTour()}
          finalText='Got it'
          goTo={goTo}
          step={step}
          title={props.title}
        />
      ,
      position: mobile ? props.mobileDirection : props.desktopDirection,
      selector: `#${props.selector}`,
      style: {
        width: 300,
        maxWidth: 300,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
      },
    },
  ]

  return (
    <Tour
      accentColor={theme.palette.primary.main}
      closeWithMask
      disableFocusLock
      disableKeyboardNavigation
      isOpen={open}
      onRequestClose={() => closeTour()}
      rounded={16}
      showButtons={false}
      showNavigation={false}
      showNavigationNumber={false}
      showNumber={false}
      steps={steps}
    />
  )
}

export default SimpleTour

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-mixed-operators */
/* eslint-disable complexity */
import { Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { onboardingEvent } from 'src/api/organisation'
import { cancelSubscriptionText } from 'src/api/payment'
import CancelModalYourAccount from 'src/components/pages/Account/Billing/BillingComponents/CancelModalYourAccount'
import Cost from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/Cost'
import CostConfirm from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/CostConfirm'
import Difficulty from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/Difficulty'
import DifficultyConfirm from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/DifficultyConfirm'
import MissingFunctionality from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/MissingFunctionality'
import MissingFunctionalityConfirm from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/MissingFunctionalityConfirm'
import NotUsing from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/NotUsing'
import NotUsingConfirm from 'src/components/pages/Account/Billing/BillingComponents/CancelPlan/NotUsingConfirm'
import type { PriceLookupKey } from 'src/models/Organisation'

type Props = {
  readonly setPriceLookupKey: (price: PriceLookupKey) => void
  readonly setCheckout: (checkout: string) => void
}

const CancelConfirmation = (props: Props) => {
  const [openCancelConfirm, setOpenCancelConfirm] = useState(false)
  const [activeSelection, setActiveSelection] = useState(0)
  const [additionalReason, setAdditionalReason] = useState<string[]>([])
  const [reason, setReason] = useState('')

  const handleDowngrade = () => {
    const closeReason = activeSelection === 1
      ? 'Too expensive'
      : activeSelection === 2
        ? 'Not sure how to use'
        : activeSelection === 3
          ? 'Missing feature'
          : activeSelection === 4
            ? 'Project is over'
            : 'Other'

    void cancelSubscriptionText(closeReason, `${additionalReason.join(', ')} ${reason}`)
    props.setPriceLookupKey('FREE')
    props.setCheckout('checkout')
  }

  const handleDowngradePre = () => {
    setOpenCancelConfirm(true)
  }

  const handleSelection = (value: number) => () => {
    setActiveSelection(value)
  }

  useEffect(() => {
    setAdditionalReason([])
  }, [activeSelection])

  useEffect(() => {
    void onboardingEvent('View cancel plan')
  }, [])

  return (
    <>
      <CancelModalYourAccount
        close={() => setOpenCancelConfirm(false)}
        handleDowngrade={handleDowngrade}
        open={openCancelConfirm}
      />
      <Stack direction='row' maxWidth={1312} spacing={2} width={1} >
        <Stack alignItems='center' direction='column' justifyContent='center' spacing={2} width={1}>
          <Stack alignItems='center' direction='column' maxWidth={650} spacing={1} width={1}>
            <Typography variant='h2'>
              Cancel plan
            </Typography>
            <Typography color='text.secondary' textAlign='center' variant='body1'>
              Are you sure you want to cancel your subscription?
              You will lose access to features that are only available on paid plans at the end of your billing
              cycle and your account will be set on a 15% commission.
            </Typography>
          </Stack>
          <Stack
            direction='row'
            justifyContent='center'
            paddingY={4}
            width={1}
          >
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
                <Stack width={1}>
                  <Cost
                    activeSelection={activeSelection}
                    handleSelection={handleSelection}
                  />
                </Stack>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
                <Stack width={1}>
                  <Difficulty
                    activeSelection={activeSelection}
                    handleSelection={handleSelection}
                  />
                </Stack>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
                <Stack width={1}>
                  <MissingFunctionality
                    activeSelection={activeSelection}
                    handleSelection={handleSelection}
                  />
                </Stack>
              </Grid>
              <Grid item lg={3} md={3} sm={6} xl={3} xs={6}>
                <Stack width={1}>
                  <NotUsing
                    activeSelection={activeSelection}
                    handleSelection={handleSelection}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          {(() => {
            switch (activeSelection) {
              case 1:
                return <CostConfirm
                  handleDowngrade={handleDowngradePre}
                  reason={reason}
                  setAdditionalReason={setAdditionalReason}
                  setReason={setReason}
                />
              case 2:
                return <DifficultyConfirm
                  handleDowngrade={handleDowngradePre}
                  reason={reason}
                  setAdditionalReason={setAdditionalReason}
                  setReason={setReason}
                />
              case 3:
                return <MissingFunctionalityConfirm
                  handleDowngrade={handleDowngradePre}
                  reason={reason}
                  setAdditionalReason={setAdditionalReason}
                  setReason={setReason}
                />
              case 4:
                return <NotUsingConfirm
                  handleDowngrade={handleDowngradePre}
                  reason={reason}
                  setReason={setReason}
                />
              default:
                return
            }
          })()}
        </Stack>
      </Stack>
    </>
  )
}

export default CancelConfirmation
